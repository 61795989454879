import React, { useContext, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@material-ui/core";
import { ArrowDropDown, ArrowRight, ExpandMore } from "@material-ui/icons";

import { AppStateContext } from "../../providers/app.provider";
import { getDashboardConfig } from "../../services/dashboard";
import { toUrl, isACP, isAP } from "../../utils/common";
import "./sidebar.scss";
import CustomModal from "./modal";
import moment from "moment";

const Sidebar = ({ show, ud }) => {
  const [state, dispatch] = useContext(AppStateContext);
  const [expanded, setExpanded] = React.useState("");
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const constructMenu = (data) => {
    const tempMenu = {};

    data.forEach((menuItem) => {
      if (!tempMenu[menuItem.category]) {
        tempMenu[menuItem.category] = [menuItem];
        return;
      }
      tempMenu[menuItem.category].push(menuItem);
    });

    dispatch({ type: "setMenu", payload: tempMenu });
  };
  
  const getConfig = () => {
    getDashboardConfig(state.user?.id || state.user?._id)
      .then(({ data }) => {
        constructMenu(data);
      })
      .catch((err) => { });
  };

  const isActive = (page) => {
    const breadCrumbs = location.pathname.split("/");
    if (breadCrumbs[breadCrumbs.length - 1] === toUrl(page)) {
      return "active";
    }

    return "";
  };

  useEffect(() => {
    if (state.isLoggedIn) {
      getConfig();
    }
  }, [state.isLoggedIn]);

  useEffect(() => {
    const breadCrumbs = location.pathname.split("/");
    setExpanded(breadCrumbs[1]);
    window.scrollTo(0, 0);
  }, [location]);

  const renderDate = () => {
    const date = new Date();

    return moment(date).startOf("day").format("DD/MM/yyyy, hh:mm A");
  };

  const getMenu = (menuItems, menuCategory) => {
    return (
      <>
        {menuItems.map((menuItem) =>
          menuItem.question ? (
            menuItem.question.map((q) => {
              let isActive = window.location.hash == `#${toUrl(q.que_text)}`;
              return (
                <Link
                  key={q.que_text}
                  onClick={() => {
                    // console.log("state.user.firstTimeUser", state.user)
                    if (q?.type == 'pdf') { window.open(q.src, '_blank') }
                    // state.user.firstTimeUser ? setOpen(true) : setOpen(false)
                  }}
                  className={`side-nav-links mt-1 ${isActive ? "active" : ""}`}
                  to={q?.type == 'pdf' ?
                    toUrl(`/${menuCategory}/${menuItem.dashboardName}#${menuItem.question[0].que_text}`) :
                    toUrl(`/${menuCategory}/${menuItem.dashboardName}#${q.que_text}`)
                  }

                >
                  <ArrowRight
                    fontSize="large"
                    style={{ fill: "#BA1F00" }}
                    hidden={!isActive}
                  />
                  <div className="side_que">
                    <span className="menu-name" style={{ fontSize: "1.1rem" }}>
                      {q.que_text}
                    </span>
                    <span style={{ fontSize: "0.9rem" }}>{q.que_desc}</span>
                  </div>
                </Link >
              );
            })
          ) : (
            <Link
              key={menuItem.dashboardName}
              className={`side-nav-links mt-1 ps-4 ${isActive(
                menuItem.dashboardName
              )}`}
              to={toUrl(`/${menuCategory}/${menuItem.dashboardName}`)}
            >
              <span className="menu-name font-14">
                {/* {isActive(menuItem.dashboardName) && <ArrowRight fontSize="large" style={{ fill: "#BA1F00" }} />} */}
                {menuItem.dashboardName}
              </span>
            </Link>
          )
        )}
        <CustomModal open={open} handleClose={handleClose}>
          <div>
            <div style={{ fontWeight: "bold" }}>
              Master Trainer Assessment Report
            </div>
            <div style={{ margin: "15px 0" }}>
              This report gives qualitative assessment of the master trainer
              during the foundation training program.
            </div>
            <div>
              Using this report you will be able to identify trainers across
              three levels:
              <div>
                <span style={{ paddingRight: "5px", fontWeight: "bold" }}>
                  Level A
                </span>
                <span>Good quality of training - Potential Super trainer</span>
              </div>
              <div>
                <span style={{ paddingRight: "5px", fontWeight: "bold" }}>
                  Level B
                </span>
                <span>Average quality of training - Good</span>
              </div>
              <div>
                <span style={{ paddingRight: "5px", fontWeight: "bold" }}>
                  Level C
                </span>
                <span>Poor quality of training - Needs improvement</span>
              </div>
            </div>

            <div
              style={{
                margin: "25px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                style={{
                  borderRadius: "25px",
                  padding: "5px 25px",
                  backgroundColor: "#FFBC36",
                }}
              >
                View Report
              </Button>
            </div>
          </div>
        </CustomModal>
      </>
    );
  };



  const renderMenuGroup = () => {
    if (isACP | isAP)
      return (
        <>
          {Object.keys(state.menu).map((menuCategory) => {
            const _menu = menuCategory.replace(/ /g, "+");
            console.log(expanded, toUrl(_menu))
            return (
              <Accordion
                key={_menu}
                className="menu-group"
                expanded={ud || expanded == toUrl(_menu)}
                onChange={handleChange(toUrl(_menu))}
                style={{ borderBottom: "1px solid #ccc" }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMore style={{ color: "#ba1f00", padding: "0" }} />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <span className="category-heading font-11">
                    {menuCategory}
                  </span>
                </AccordionSummary>
                <AccordionDetails
                  className="panel-details"
                  style={{
                    display: "block",
                    // paddingTop: 0,
                    paddingLeft: 0,
                  }}
                >
                  {getMenu(state.menu[menuCategory], _menu)}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      );
    else
      return (
        <>
          {Object.keys(state.menu).map((menuCategory) => {
            return (
              <div clasxsName="menu-group mb-5 mt-4" key={menuCategory}>
                <span className="category-heading font-11 ps-4">
                  {menuCategory}
                </span>
                {getMenu(state.menu[menuCategory], menuCategory)}
              </div>
            );
          })}
        </>
      );
  };

  return (
    <div style={{ padding: ud ? "1% 0 10px" : '' }} className={`sidebar ${isACP ? "acp-sidebar" : ""}`} hidden={!show}>
      <div className="sidebar-body">
        <div className={`side-nav ${isACP ? "" : ""}`}>{renderMenuGroup()}</div>
        {/* <div className="updatedDate">{`Data as on ${renderDate()}`}</div> */}
      </div>
    </div>
  );
};

export default Sidebar;
